<nav class="navbar navbar-light main-header shadow-sm">
  <a class="navbar-brand text-dark " href="#">
    <img src="https://images.prismic.io/lipatinpdn/45174b3e-e9e7-4672-95bc-c554c511f14e_Logo+Liga+de+Patinaje+de+Antioquia_Mesa+de+trabajo+1.png?auto=compress,format" alt="Logo Lipatin" width="120"  class="d-inline-block align-text-top">
  </a>
    <div class="main-header__title">
      <div class="lead text-success m-2">
         Matricula Digital <label class="text-muted">| Escuela de Formación Deportiva</label>
      </div>
      
      <span>
        <h5><span class="text-success"></span> </h5>
      </span>
    </div>

  </nav>